import { StarIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text, Heading } from "@chakra-ui/react";
import React from "react";

const Opinion = ({ name, text, img, isExpert }) => {
  return (
    <Flex
      backgroundColor="#efefef"
      pt={6}
      pb={6}
      pr={10}
      pl={10}
      mt={10}
      align="center"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Flex w={{ base: "100%", md: "30%" }} marginRight={{ base: 0, md: 10 }} flexDirection="column" align="center">
        <Flex hidden={!isExpert} flexDirection="column" width="100%" align="center">
          <Heading fontSize="20px">Opinia Eksperta!</Heading>
          <Flex>
            <StarIcon color="#FFD700" boxSize={6} />
            <StarIcon color="#FFD700" boxSize={6} />
            <StarIcon color="#FFD700" boxSize={6} />
            <StarIcon color="#FFD700" boxSize={6} />
            <StarIcon color="#FFD700" boxSize={6} />
          </Flex>
        </Flex>
        <Image src={img} maxWidth="100%" mt={isExpert ? 6 : 0} />
      </Flex>
      <Box w={{ base: "100%", md: "70%" }}>
        <Text fontStyle="italic" textAlign="justify">
          "{text}"
        </Text>
        <Text mt="10px" fontWeight={800} >{name}</Text>
      </Box>
    </Flex>
  );
};

export default Opinion;
