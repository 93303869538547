import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import SuplRev from "./SuplRev";
import About from "./About";
import Opinion from "./Opinion";

import himg from "../images/header-photo.jpeg";

import moringInfo from "../components/data/AboutMoring";
import redminInfo from "./data/AboutRedmin";
import fatInfo from "./data/AboutFat";
import burnInfo from "./data/AboutBurn";
import slimInfo from "./data/AboutSlim";
import Form from "./Form";

const MainFlow = () => {
  const moringInfoRef = useRef(null);
  const moringRevRef = useRef(null);
  const redminInfoRef = useRef(null);
  const redminRevRef = useRef(null);
  const fatInfoRef = useRef(null);
  const fatRevRef = useRef(null);
  const burnInfoRef = useRef(null);
  const burnRevRef = useRef(null);
  const slimInfoRef = useRef(null);
  const slimRevRef = useRef(null);
  const [openForm, setOpenForm] = useState(0);
  const [timeLeft, setTimeLeft] = useState(600)

  const scrollIntoView = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const countDown = setInterval(() => {
      setTimeLeft(prevTime => prevTime -1);
    }, 1000)
    if (timeLeft === 0) clearInterval(countDown)

    return () => clearInterval(countDown)
  }, [timeLeft])

  //1 dla moringa 2 dla redmina
  const popForm = (op) => {
    setOpenForm(op)
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow ='hidden'
  }

  const closeForm = () => {
    setOpenForm(0)
    document.body.style.overflow = 'auto'
    document.documentElement.style.overflow ='auto'
  }

  return (
    <Box id="main">
      <Box hidden={openForm===0 || openForm===2} >
        <Form img={moringInfo.prodImg} name={"Moring Slim"} closeButton={closeForm} id={23} time={timeLeft} />
      </Box>
      <Box hidden={openForm===0 || openForm===1} >
        <Form img={redminInfo.prodImg} name={"Redmin"} closeButton={closeForm} id={54} time={timeLeft} />
      </Box>
      <Box position="relative">
        <Image src={himg} alt="Polskie forum dietetyczne" padding="0 10px" />
        <Box
          position="absolute"
          width="calc(100% - 20px)"
          left="10px"
          bottom="0"
          padding="0 0 20px 20px"
          background="linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7))"
        >
          <Text
            fontSize={{ base: 5, md: 10 }}
            fontWeight="600"
            color="white"
            background="blue.600"
            width="fit-content"
            padding="5px"
          >
            RANKINGI
          </Text>
          <Heading
            as="h1"
            fontSize={{ base: 15, md: 30 }}
            textTransform="uppercase"
            color="white"
          >
            Eksperci są jednogłośni -
          </Heading>
          <Heading
            fontSize={{ base: 15, md: 30 }}
            textTransform="uppercase"
            color="white"
          >
            Ranking najlepszych środków odchudzających
          </Heading>
          <Heading
            fontSize={{ base: 10, md: 20 }}
            fontWeight="400"
            color="white"
          >
            Marzec 2024
          </Heading>
          <Flex>
            <Image />
            <Text></Text>
          </Flex>
        </Box>
      </Box>
      <Box padding="10px">
        <Heading fontSize="25px" fontWeight="600" margin="20px 0">
          SKUTECZNE SPOSOBY NA SZCZUPŁE CIAŁO – RANKING NAJLEPSZYCH ŚRODKÓW
          ODCHUDZAJĄCYCH Marzec 2024
        </Heading>
        <Text textAlign="justify">
          Na rynku dostępnych jest tak wiele produktów, które mają pomóc w
          odchudzaniu, że trudno się w tych ofertach odnaleźć. Osoby szukające
          skutecznych tabletek na nadwagę nie wiedzą, co wybrać, by szybko
          schudnąć. Dlatego przedstawiamy ranking tabletek na odchudzanie.
        </Text>
        <br />
        <Text textAlign="justify">
          Produkty wybraliśmy na podstawie opinii internautów, biorąc pod uwagę
          takie parametry tabletek odchudzających, jak: skuteczność, trwałość
          efektów oraz zdolność do przyspieszenia metabolizmu. Ranking jest na
          bieżąco aktualizowany – bierzemy pod uwagę wszystkie pojawiające się
          nowości, by dać Ci możliwie jak najlepszy wybór tabletek na
          odchudzanie. Sprawdź najświeższe recenzje!
        </Text>
      </Box>
      <Box padding="10px" margin="40px 0">
        <SuplRev
          img={moringInfo.prodImg}
          name={moringInfo.prodName}
          data={moringInfo.revData}
          rev={moringInfo.rev}
          baner={true}
          buy={true}
          infoScroll={() => scrollIntoView(moringInfoRef)}
          revScroll={() => scrollIntoView(moringRevRef)}
          buyButton={() => popForm(1)}
        />
        <Box borderBottom="2px dashed lightgray" margin="40px 0" />
        <SuplRev
          img={redminInfo.prodImg}
          name={redminInfo.prodName}
          data={redminInfo.revData}
          rev={redminInfo.rev}
          baner={true}
          buy={true}
          infoScroll={() => scrollIntoView(redminInfoRef)}
          revScroll={() => scrollIntoView(redminRevRef)}
          buyButton={() => popForm(2)}
        />
        <Box borderBottom="2px dashed lightgray" margin="40px 0" />
        <SuplRev
          img={fatInfo.prodImg}
          name={fatInfo.prodName}
          data={fatInfo.revData}
          rev={fatInfo.rev}
          baner={false}
          buy={false}
          infoScroll={() => scrollIntoView(fatInfoRef)}
          revScroll={() => scrollIntoView(fatRevRef)}
        />
        <Box borderBottom="2px dashed lightgray" margin="40px 0" />
        <SuplRev
          img={burnInfo.prodImg}
          name={burnInfo.prodName}
          data={burnInfo.revData}
          rev={burnInfo.rev}
          baner={false}
          buy={false}
          infoScroll={() => scrollIntoView(burnInfoRef)}
          revScroll={() => scrollIntoView(burnRevRef)}
        />
        <Box borderBottom="2px dashed lightgray" margin="40px 0" />
        <SuplRev
          img={slimInfo.prodImg}
          name={slimInfo.prodName}
          data={slimInfo.revData}
          rev={slimInfo.rev}
          baner={false}
          buy={false}
          infoScroll={() => scrollIntoView(slimInfoRef)}
          revScroll={() => scrollIntoView(slimRevRef)}
        />
      </Box>
      <Box padding="10px">
        <Heading textAlign="center" margin="40px 0">
          Ranking na Marzec 2024
        </Heading>
        <Box marginTop="40px">
          <span ref={moringInfoRef}></span>
          <About
            img={moringInfo.prodImg}
            name={moringInfo.prodName}
            buy={true}
            readerText={moringInfo.readerText}
            changeImg={moringInfo.changeImg}
            readerName={moringInfo.readerName}
            text={moringInfo.text}
            pros={moringInfo.pros}
            cons={moringInfo.cons}
            buyButton={() => popForm(1)}
            time={timeLeft}
          />
          <Box marginTop="40px">
            <span ref={moringRevRef}></span>
            <Heading fontWeight="500" fontSize={{ base: "20px", md: "30px" }}>
              OPINIE:
            </Heading>
            <Opinion
              isExpert={true}
              name={moringInfo.revEx.name}
              img={moringInfo.revEx.img}
              text={moringInfo.revEx.text}
            />
            <Opinion
              isExpert={false}
              name={moringInfo.rev1.name}
              img={moringInfo.rev1.img}
              text={moringInfo.rev1.text}
            />
            <Opinion
              isExpert={false}
              name={moringInfo.rev2.name}
              img={moringInfo.rev2.img}
              text={moringInfo.rev2.text}
            />
            <Opinion
              isExpert={false}
              name={moringInfo.rev3.name}
              img={moringInfo.rev3.img}
              text={moringInfo.rev3.text}
            />
          </Box>
        </Box>
        <Box marginTop="40px">
          <span ref={redminInfoRef}></span>
          <About
            img={redminInfo.prodImg}
            name={redminInfo.prodName}
            buy={true}
            readerText={redminInfo.readerText}
            changeImg={redminInfo.changeImg}
            readerName={redminInfo.readerName}
            text={redminInfo.text}
            pros={redminInfo.pros}
            cons={redminInfo.cons}
            buyButton={() => popForm(2)}
            time={timeLeft}
          />
          <Box marginTop="40px">
            <span ref={redminRevRef}></span>
            <Heading fontWeight="500" fontSize={{ base: "20px", md: "30px" }}>
              OPINIE:
            </Heading>
            <Opinion
              isExpert={true}
              name={redminInfo.revEx.name}
              img={redminInfo.revEx.img}
              text={redminInfo.revEx.text}
            />
            <Opinion
              isExpert={false}
              name={redminInfo.rev1.name}
              img={redminInfo.rev1.img}
              text={redminInfo.rev1.text}
            />
            <Opinion
              isExpert={false}
              name={redminInfo.rev2.name}
              img={redminInfo.rev2.img}
              text={redminInfo.rev2.text}
            />
            <Opinion
              isExpert={false}
              name={redminInfo.rev3.name}
              img={redminInfo.rev3.img}
              text={redminInfo.rev3.text}
            />
          </Box>
        </Box>
        <Box marginTop="40px">
          <span ref={fatInfoRef}></span>
          <About
            img={fatInfo.prodImg}
            name={fatInfo.prodName}
            buy={false}
            readerText={""}
            changeImg={null}
            readerName={""}
            text={fatInfo.text}
            pros={fatInfo.pros}
            cons={fatInfo.cons}
          />
          <Box marginTop="40px">
            <span ref={fatRevRef}></span>
            <Heading fontWeight="500" fontSize={{ base: "20px", md: "30px" }}>
              OPINIE:
            </Heading>
            <Opinion
              isExpert={false}
              name={fatInfo.rev1.name}
              text={fatInfo.rev1.text}
              img={fatInfo.rev1.img}
            />
            <Opinion
              isExpert={false}
              name={fatInfo.rev2.name}
              text={fatInfo.rev2.text}
              img={fatInfo.rev2.img}
            />
          </Box>
        </Box>
        <Box marginTop="40px">
          <span ref={burnInfoRef}></span>
          <About
            img={burnInfo.prodImg}
            name={burnInfo.prodName}
            buy={false}
            readerText={""}
            changeImg={null}
            readerName={""}
            text={burnInfo.text}
            pros={burnInfo.pros}
            cons={burnInfo.cons}
          />
          <Box marginTop="40px">
            <span ref={burnRevRef}></span>
            <Heading fontWeight="500" fontSize={{ base: "20px", md: "30px" }}>
              OPINIE:
            </Heading>
            <Opinion
              isExpert={false}
              name={burnInfo.rev1.name}
              text={burnInfo.rev1.text}
              img={burnInfo.rev1.img}
            />
            <Opinion
              isExpert={false}
              name={burnInfo.rev2.name}
              text={burnInfo.rev2.text}
              img={burnInfo.rev2.img}
            />
          </Box>
        </Box>
        <Box marginTop="40px">
          <span ref={slimInfoRef}></span>
          <About
            img={slimInfo.prodImg}
            name={slimInfo.prodName}
            buy={false}
            readerText={""}
            changeImg={null}
            readerName={""}
            text={slimInfo.text}
            pros={slimInfo.pros}
            cons={slimInfo.cons}
          />
          <Box>
            <span ref={slimRevRef}></span>
            <Heading fontWeight="500" fontSize={{ base: "20px", md: "30px" }}>
              OPINIE:
            </Heading>
            <Opinion
              isExpert={false}
              name={slimInfo.rev1.name}
              text={slimInfo.rev1.text}
              img={slimInfo.rev1.img}
            />
            <Opinion
              isExpert={false}
              name={slimInfo.rev2.name}
              text={slimInfo.rev2.text}
              img={slimInfo.rev2.img}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainFlow;
