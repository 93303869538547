// import { Box, Flex, Image } from "@chakra-ui/react";
import Layout from "./components/Layout";
// import Add from "./components/Add";
// import logos from "../src/images/logo-forum.png"
import { Route, Routes } from "react-router-dom";
import Thankyou from "./components/Thankyou";


function App() {
  return (
    // <Box className="App" width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
    //   <Flex padding={{base: 5, md: 3}} maring="0 10px" height={{base: "fit-content", md: "140px"}} width={{base: "100%", md: "90%", lg: "70%", xl: "50%"}} flexDirection={{base: "column", sm:"row"}} justifyContent={{base: "space-evenly", md: "space-between"}} alignItems="center">
    //     <Image src={logos} maxWidth="250px" margin={{base: "20px"}} />
    //     <p>Ranking 2024: środki odchudzające</p>
    //   </Flex>
    //   <Flex width={{base: "100%", md: "90%", lg: "70%", xl:"50%"}}>
    //     <Flex width={{base: '100%', md: "80%"}}>
    //       <Layout />
    //     </Flex>
    //     <Flex marginLeft="30px" hideBelow="md" width="25%">
    //       <Add />
    //     </Flex>
    //   </Flex>
    // </Box>
    <Routes>
      <Route index element={<Layout />} />
      <Route path="/thankyou" element={<Thankyou />} />
    </Routes>
  );
}

export default App;
