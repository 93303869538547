import { Button, Flex, Image, Box, Text, Heading } from "@chakra-ui/react";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import React from "react";
import badge from "../images/badge.png";

const About = ({
  img,
  name,
  buy,
  readerText,
  readerName,
  changeImg,
  text,
  pros,
  cons,
  buyButton,
  time,
}) => {
  const min = Math.floor(time / 60);
  const sec = time % 60;
  const addZero = (val) => {
    return val < 10 ? `0${val}` : val
  }
  return (
    <Flex
      marginTop="20px"
      flexDirection={{ base: "column", md: "row" }}
      align="flex-start"
    >
      <Flex
        w={{ base: "100%", md: "35%" }}
        flexDirection="column"
        align="center"
      >
        <Heading
          hideFrom="md"
          fontSize="25px"
          marginBottom="20px"
          textAlign="center"
        >
          {name}
        </Heading>
        <Flex position="relative" flexDirection="column" align="center">
          <Image src={img} maxWidth={{ base: 20, md: 100 }} />
          <Image
            position="absolute"
            maxWidth={{ base: "75%", md: "75%" }}
            bottom="10px"
            hidden={!name.includes("#1")}
            src={badge}
          />
        </Flex>
        <Flex hidden={!buy} flexDirection="column" align="center">
          <Button
            color="white"
            background="red.500"
            borderRadius="25px"
            onClick={buyButton}
          >
            Zapisz się
          </Button>
          <Box marginBottom="20px" marginTop="20px">
            <Text textAlign="center" fontWeight={600}>
              Zapisz się aby skorzystać z refundacji -50%.
            </Text>
            <Text textAlign="center" fontWeight={600}>
              Do końca zostało: <Box as="span" fontSize="20px" color="red.500" >{addZero(min)}:{addZero(sec)}</Box>
            </Text>
          </Box>
          <Box>
            <Text textAlign="justify" fontStyle="italic">
              "{readerText}"
            </Text>
            <Image borderRadius="25px" mt={3} src={changeImg} />
            <Text fontWeight="700" fontStyle="italic" textAlign="center">
              {readerName}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex
        w={{ base: "100%", md: "65%" }}
        flexDirection="column"
        paddingLeft={{ base: "0", md: "20px" }}
      >
        <Heading
          hideBelow="md"
          fontSize="25px"
          marginBottom="20px"
          textAlign="center"
        >
          {name}
        </Heading>
        <Box>
          {text.map((text, index) => (
            <Text key={index} marginBottom="20px" textAlign="justify">
              <CheckIcon color="green.400" boxSize={{ base: 4, md: 6 }} mr={1} />
              {text}
            </Text>
          ))}
        </Box>
        <Flex justify="space-around">
          <Flex flexDirection="column" width="50%" align="flex-start">
            <Heading fontSize="20px">Zalety:</Heading>
            {pros.map((text, index) => (
              <Text key={index} fontSize="12px">
                <CheckIcon
                  color="green.400"
                  boxSize={{ base: 4, md: 6 }}
                  m={1}
                />
                {text}
              </Text>
            ))}
          </Flex>
          <Flex flexDirection="column" width="50%" align="flex-start">
            <Heading fontSize="20px">Wady:</Heading>
            {cons.map((text, index) => (
              <Text key={index} fontSize="12px">
                <CloseIcon color="red.400" boxSize={{ base: 3, md: 5 }} m={1} />
                {text}
              </Text>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default About;
