import redmin from "../../images/redimin30.png"
import redmin_after from "../../images/redmin_after.jpeg"
import redmin_revEx from "../../images/redmin_revEx.jpeg"
import redmin_rev1 from "../../images/redmin_rev1.jpeg"
import redmin_rev2 from "../../images/redmin_rev2.jpeg"
import redmin_rev3 from "../../images/redmin_rev3.jpeg"

const redminInfo = {
    prodImg: redmin,
    prodName: "#2 Redmin",
    revData: [93, 92, 99, 100, 91],
    rev: 9.4,
    readerText: "W zeszłym miesiącu zaczęłam brać Redmin, teraz jestem w normalnej formie, chociaż jem co chcę. Schudłam 10 kg! Myślę, że to najlepszy produkt odchudzający, jaki kiedykolwiek próbowałam. Jest super, nie muszę już chodzić na siłownię, nie mam na to czasu i ochoty.",
    changeImg: redmin_after,
    readerName: "Agnieszka z Grójca, 26 lat",
    text: ["REDIMIN to suplement diety, który pomoże Ci bez wyrzeczeń i efektu jo-jo pozbyć się zbędnych kilogramów. Jego formuła skupia się na skutecznej aktywacji metabolizmu, znacząco redukując apetyt i skutecznie spalając nagromadzony tłuszcz. To nie tylko produkt odchudzający, to klucz do transformacji sylwetki bez zbędnych wyrzeczeń.","Unikalna mieszanka składników, takich jak Polimnia Jadalna, L-karnityna, guarana i wiele innych, tworzy formułę, która nie tylko wspomaga proces utraty wagi, ale również korzystnie wpływa na ogólne zdrowie organizmu.", "Dzięki Polimnii Jadalnej, suplement dba o zdrowie jelit, regulując poziom cukru we krwi i działając jako probiotyk. L-karnityna, z kolei, odgrywa kluczową rolę w metabolizmie tłuszczów, wspomagając przekształcanie ich w energię. Guarana, naturalne źródło kofeiny, pobudza układ nerwowy, zwiększa poziom energii i przyspiesza tempo przemiany materii, przyczyniając się do spalania tłuszczu.", "REDIMIN to wszechstronny sojusznik zdrowego stylu życia. Pozbądź się zbędnych kilogramów bez wyrzeczeń i poczuj korzyści dla całego organizmu, ciesząc się lepszym samopoczuciem już dziś!"],
    pros: ["przyspieszenie metabolizmu", "mniejszy apetyt", "spalanie nagromadzonej tkanki tłuszczowej", "bez efektu jo-jo", "skuteczność i bezpieczeństwo stosowania"],
    cons: ["generalnie brak, niektórzy jedynie wspominali, że kapsułka jest dość trudna do połknięcia"],
    revEx: {
        name: "Błażej Jakubowski",
        text: "Ekstrakt z korzenia Polimni Jadalnej, znany również jako Jiaogulan, może korzystnie wpływać na odchudzanie poprzez regulację poziomu cukru we krwi, poprawę metabolizmu, redukcję stresu, zwiększenie wydolności fizycznej i poziomu energii, poprawę jakości snu oraz zmniejszenie apetytu, co może przyczynić się do lepszych rezultatów w utracie wagi.",
        img: redmin_revEx
    },
    rev1:{name: "Maria z Lemborka",
                img: redmin_rev1,
                text: "Redmin to absolutny przełom w mojej drodze do utraty wagi! Po wielu nieudanych próbach znalezienia skutecznego suplementu, natrafiłam na Redmin i nie mogłam być bardziej zadowolona. Moja energia skoczyła na niebotyczny poziom, a apetyt został skutecznie zredukowany. Regularne stosowanie sprawiło, że zauważyłam wyraźne zmniejszenie obwodu w talii i odczuwam ogromną motywację do dalszych zmian. Polecam go wszystkim, którzy pragną skutecznie i bezpiecznie schudnąć."
    },
    rev2: {
        name: "Hanna z Gdyni",
        img: redmin_rev2,
        text: "Redmin to mój tajny składnik sukcesu w walce z nadwagą! Jako aktywna kobieta, czasami trudno mi było znaleźć równowagę między codziennymi obowiązkami a dbaniem o siebie. Dzięki Redminowi, moje wysiłki w kierunku odchudzania stały się znacznie skuteczniejsze. Nie tylko zauważyłam spadek wagi, ale także poprawę nastroju i koncentracji. To naprawdę rewolucyjny produkt, który zmienił moje podejście do zdrowego stylu życia!"
    },
    rev3: {
        name: "Jakub z Wadowic",
        img: redmin_rev3,
        text: "Redmin to nie tylko suplement na odchudzanie, to prawdziwy game changer! Jako facet, zawsze byłem sceptyczny co do skuteczności suplementów, ale Redmin mnie pozytywnie zaskoczył. Po kilku tygodniach stosowania zauważyłem nie tylko spadek wagi, ale także wzrost energii i poprawę wydolności podczas treningów. Jestem pod wrażeniem, jak szybko zaczęły się zmiany, a efekty utrzymują się długoterminowo. Redmin to niezbędny element mojej codziennej rutyny, którego nie zamieniłbym na żaden inny produkt na rynku!"
    },
}

export default redminInfo