import fat from "../../images/fatfix.jpeg"
import fat_rev1 from "../../images/fat_rev1.jpeg"
import fat_rev2 from "../../images/fat_rev2.jpeg"

const fatInfo = {
    prodImg: fat,
    prodName: "#3 Fatfix",
    revData: [72, 73, 68, 79, 66],
    rev: 7.3,
    text: ["Kolejne miejsce zajmuje Fatfix. Kapsułki na odchudzanie, które dają trochę słabsze rezultaty, ale nadal są dość skuteczne. Chociaż trzeba przyznać, że najpierw pojawia się efekt oczyszczenia organizmu z zalegających złogów a dopiero potem waga zaczyna spadać. Stąd wrażenie, że nie są to najskuteczniejsze tabletki na odchudzanie.","Ogólnie, jeśli zastanawiasz się, jak szybko schudnąć, to Fatfix może okazać się niewystarczającym wyborem. Te tabletki wspomagają proces spalania tłuszczu nawet podczas spoczynku, ale na efekty trzeba naprawdę długo czekać. Użytkownicy dość często podkreślali długi czas oczekiwania na spadającą wagę. Być może winę ponoszą tutaj źle dobrane proporcje składników aktywnych.", "Skład jest naturalny i dość bogaty. Można w nim znaleźć: ekstrakty z zielonej herbaty, owoców garcinia cambogia, gorzkiej pomarańczy, jagód acai czy chociażby cayenne. Ich działanie polega głównie na podniesieniu temperatury ciała i uwolnieniu z organizmu zalegających toksyn. Szkoda tylko, że efekt odchudzający to tak naprawdę drugorzędny skutek stosowania tego preparatu."],
    pros: ["oczyszczanie organizmu", "spalanie tkanki tłuszczowej", "bogate substancje aktywne"],
    cons: ["mniejsze efekty niż przy użyciu suplementów wymienionych wyżej", "stosunkowo długie stosowanie, żeby zobaczyć efekty", "źle dobrane proporcje składników aktywnych przez co efekt odchudzający pojawia się znacznie później"],
    rev1: {
        name: "Gabierla z Konina",
        text: "Fatfix pomogło mi zrzucić kilka kilogramów. Zrzuciłam moje pociążowe kilogramy, sylwetka lepiej wygląda, ale na efekty musiałam długo czekać. Na samym początku nie było żadnej zmiany i tylko fakt, że postanowiłam nie odpuszczać, uratował sytuację. Wytrwale stosowałam Fatfix przez kilka miesięcy i wreszcie dałam radę. Ogólnie produkt mogę polecić, jeśli komuś się nie spieszy z zrzuceniem nadwagi.",
        img: fat_rev1
    },
    rev2: {
        name: "Weronika z Płocka",
        text: "Stosowałam tabletki na odchudzanie Fatfix przez kilka miesięcy, ale efektów spektakularnych nie zauważyłam. Oczywiście waga spadła, ale dla mnie za mało i za wolno. Jednak od środka wspomagającego odchudzanie wymagam szybszego działania.",
        img: fat_rev2
    }
}

export default fatInfo