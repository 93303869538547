import {
  Button,
  Flex,
  Heading,
  Image,
  Progress,
  Text,
} from "@chakra-ui/react";
import React from "react";
import badge from "../images/badge.png"
import badge1 from "../images/badge-1.jpg";
import badge2 from "../images/badge-2.jpg";
import badge3 from "../images/badge-3.jpg";

const SuplRev = ({ img, name, data, rev, baner, buy, infoScroll, revScroll, buyButton }) => {
  return (
    <Flex flexWrap="wrap" align="center" flexDirection={{base: "column", md: "row"}} >
      <Flex position="relative" flexDirection="column" align="center">
        <Image src={img} maxWidth={{base: 20,  md: 100 }} marginRight={{ md: 10 }} />
        <Image position="absolute" maxWidth={{base: "75%", md: "50%"}} bottom="0" marginRight={{ md: 10 }}  hidden={!name.includes("#1")} src={badge} />
      </Flex>
      <Flex flex={4} flexDirection={{base: "column", md: "row"}} width={{base: "100%"}} >
        <Flex flex={3}>
          <Flex flexDirection="column" flex={1}>
            <Heading
              fontSize={{ base: "15px", md: "25px" }}
              marginBottom="10px"
            >
              {name}
            </Heading>
            <Text>Przyspiesznie metabolizmu:</Text>
            <Text>Brak efektu jo-jo:</Text>
            <Text>Skuteczność produktu:</Text>
            <Text>Trwałość efektów:</Text>
            <Text>Cena:</Text>
            <Flex marginTop="10px" width={{base:"120%", md:"100%"}} justify="space-around">
              <Button color="white" background="green.500" borderRadius="25px" onClick={infoScroll} marginRight="5%">
                O produkcie
              </Button>
              <Button color="white" background="green.500" borderRadius="25px" onClick={revScroll}>
                Opinie
              </Button>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            flex={1}
            justifyContent="center"
            marginBottom={{base: "12px", md:"8px"}}
          >
            <Flex justifyContent="space-between" marginRight={{base: 0, md: 4}} align="center">
              <Progress colorScheme="green" value={data[0]} flex={1} mr={2} />
              <Text width={10} textAlign="center">{data[0] / 10}</Text>
            </Flex>
            <Flex justifyContent="space-between" marginRight={{base: 0, md: 4}} align="center">
              <Progress colorScheme="green" value={data[1]} flex={1} mr={2} />
              <Text width={10} textAlign="center">{data[1] / 10}</Text>
            </Flex>
            <Flex justifyContent="space-between" marginRight={{base: 0, md: 4}} align="center">
              <Progress colorScheme="green" value={data[2]}  flex={1} mr={2} />
              <Text width={10} textAlign="center">{data[2] / 10}</Text>
            </Flex>
            <Flex justifyContent="space-between" marginRight={{base: 0, md: 4}} align="center">
              <Progress colorScheme="green" value={data[3]}  flex={1} mr={2} />
              <Text width={10} textAlign="center">{data[3] / 10}</Text>
            </Flex>
            <Flex justifyContent="space-between" marginRight={{base: 0, md: 4}} align="center">
              <Progress colorScheme="green" value={data[4]} flex={1} mr={2} />
              <Text width={10} textAlign="center">{data[4] / 10}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" align="center" justify="center" flex="1" marginTop={{base: "20px", md: "0"}}>
          <Heading fontSize="20px">OCENA</Heading>
          <Text
            margin="20px 0"
            padding={5}
            background={baner ? "green.400" : "gray.600"}
            borderRadius="25px"
            fontWeight="800"
            fontSize={{ base: "15px", md: "25px" }}
            color="white"
          >
            {rev}
          </Text>
          <Button  hidden={!buy} onClick={buyButton} width={{base: "70%"}} color="white" background="red.500" borderRadius="25px" marginBottom={{base: "15px", md: "0"}} size="md" fontSize={{base: 20, md: 15 }} p={{base: 2, md: 6}} >
            Kup teraz
          </Button>
        </Flex>
      </Flex>
      <Flex hidden={!baner} flexDirection={{base: "column", md: "row"}} width="100%" >
        <Flex margin="0 15px" align="center" width="100%" justify={{base: "center", md: ''}}>
          <Image src={badge1} marginRight={4} />
          <Text>Zweryfikowany</Text>
        </Flex>
        <Flex margin="0 15px" align="center" width="100%" justify={{base: "center", md: ''}}>
          <Image src={badge2} marginRight={4} />
          <Text>Bezpieczeństwo zakupu</Text>
        </Flex>
        <Flex margin="0 15px" align="center" width="100%" justify={{base: "center", md: ''}}>
          <Image src={badge3} marginRight={4} />
          <Text>Bezpieczeństwo stosowania</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SuplRev;
