import { Flex, Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'

const Thankyou = () => {
  return (
    <Flex width="100vw" height="100vh" background="#f2f2f2" justify="center" align="center" overflow="hidden" >
      <Box background="white" padding={20} borderRadius="25px" >
        <Heading textAlign="center" mb={6} fontSize={50} fontWeight="800" color="green.600" >Dziękujemy!</Heading>
        <Text fontSize={20}>Wkrótce skontaktuje się z Tobą nasz konsultant w celu ustalenia adresu wysyłki.</Text>
      </Box>
    </Flex>
  )
}

export default Thankyou