import { Heading, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import pack from "../images/moringbadge.png";

const Add = () => {
  return (
    <Flex
      border="1px #e3d9da solid"
      height="fit-content"
      width="100%"
      flexDirection="column"
      padding="15px"
      alignItems="center"
      position="sticky"
      top="20px"
    >
      <Heading
        fontSize="20px"
        fontWeight="600"
        padding="10px"
        textAlign="center"
        width="100%"
        background="black"
        color="white"
      >
        #1 MORING SLIM
      </Heading>
      <Image marginTop="20px" src={pack} alt="packshot" maxWidth="180px" />
      <Heading fontWeight="600" fontSize="20px" textAlign="center">
        Zwycięzca rankingu 2024
      </Heading>
      <Text>Strona producenta</Text>
    </Flex>
  );
};

export default Add;
