import slim from "../../images/sliminator.png"
import slim_rev1 from "../../images/slim_rev1.jpeg"
import slim_rev2 from "../../images/slim_rev2.jpeg"

const slimInfo = {
    prodImg: slim,
    prodName: "#5 Sliminator",
    revData: [60, 62, 74, 69, 68],
    rev: 6.6,
    text: ["Ostatnie miejsce to Sliminator. Również spala tłuszcz i przyspiesza metabolizm. Rezultaty stosowania są całkiem dobre. Preparat poprawia samopoczucie, ale trzeba wziąć pod uwagę jeszcze kilka rzeczy.", "Po pierwsze z tego, co udało nam się dowiedzieć, w składzie znajduje się mała liczba składników aktywnych (pieprz cayenne i jagody Acai), co może być przyczyną mniej widocznych efektów niż przy innych produktach.", "Po drugie cena – dość drogi suplement, jedno opakowanie wystarcza tylko na 15 dni, ponieważ znajdziesz w nim zaledwie 30 kapsułek. Miesięczna kuracja jest więc kosztowna.", "Po trzecie zaś Sliminator to produkt, który nie do końca radzi sobie z hamowaniem apetytu, a efekty, o jakich mówi producent, są nierealne do osiągnięcia."],
    pros: ["daje niezłe efekty", "przyspiesza metabolizm", "poprawia samopoczucie"],
    cons: ["wskazywano, że preparat kiepsko radzi sobie z hamowaniem apetytu, zwłaszcza wieczorami", "opakowanie wystarcza tylko na 15 dni, zatem miesięczna kuracja wymaga 2 opakowań", "nierealistyczne obietnice (w artykułach o preparacie można przeczytać, że po 1 dniu waga spada o 2 kg, a ogółem nawet aż o 21 kg w ciągu miesiąca!)", "skład mógłby być bogatszy"],
    rev1: {
        name: "Basia z Opola",
        img: slim_rev1,
        text: "Miałam 20 kilo nadwagi, więc wybrałam coś, co obiecywało taki spadek wagi w miesiąc. Zawiodłam się, efekty były 4 razy mniejsze... Tutaj na pewno minus, tak samo za cenę tej kuracji... Jeśli nie będziemy oczekiwać cholera wie czego i stać nas na spory wydatek na opakowania to Sliminator może być całkiem przydatny."
    },
    rev2: {
        name: "Olek z Katowic",
        img: slim_rev2,
        text: "Zdecydowałem się na Sliminator z nadzieją, że pomoże mi schudnąć chociaż połowę tego, co miałem do zrzucenia. Niestety nic takiego się nie zadziało. Owszem waga spadała, ale bardzo powoli. No i niestety jak chciałem uzupełnić zapasy, pojawił się problem. Cena się zmieniła i zdecydowałem, że nie warto. Lepiej poszukać czegoś, co naprawdę pomoże w krótkim czasie."
    }
}
export default slimInfo