import burn from "../../images/burnbooster.png"
import burn_rev1 from "../../images/burn_rev1.jpeg"
import burn_rev2 from "../../images/burn_rev2.jpeg"

const burnInfo = {
    prodImg: burn,
    prodName: "#4 Burnbooster",
    revData: [70, 66, 63, 71, 73],
    rev: 7.1,
    text: ["Czwarte miejsce w rankingu budzi wątpliwości. BurnBooster został zepchnięty na drugą pozycję przez innowacyjną formułę DietFactor, ale warto zastanowić się nad skutecznością tego preparatu. Mimo że był na podium, nie można przypisywać mu wyjątkowych osiągnięć. Skutki uboczne mogą wystąpić, a efekt jo-jo nie jest wykluczony. Naturalny skład, choć brzmi obiecująco, nie zawsze przekłada się na bezpieczeństwo użytkowania.", "Nie ma pewności, czy BurnBooster faktycznie działa. Brak jednoznacznych dowodów na skuteczność tego suplementu oraz liczne negatywne recenzje mogą budzić wątpliwości co do jego rzeczywistych korzyści. Składniki takie jak zielony jęczmień czy pieprz kajeński, choć mogą brzmieć zachęcająco, niekoniecznie przekładają się na oczekiwane rezultaty.", "Zalecamy ostrożność i konsultację z lekarzem przed rozpoczęciem stosowania BurnBooster. Istnieją inne, bardziej wiarygodne opcje na rynku, które mogą być skuteczniejsze i bezpieczniejsze dla zdrowia."],
    pros: ["przyspieszenie metabolizmu", "mniejszy apetyt", "bez efektu jo-jo"],
    cons: ["brak skuteczności", "średnie bezpieczeństwo", "nie radzi sobie z hamowaniem apetytu"],
    rev1: {
        name: "Basia z Warszawy",
        text: "Miałam 20 kilo nadwagi, więc wybrałam coś, co obiecywało taki spadek wagi w miesiąc. Zawiodłam się, efekty były 4 razy mniejsze... Tutaj na pewno minus, tak samo za cenę tej kuracji... Jeśli nie będziemy oczekiwać cholera wie czego i stać nas na spory wydatek na opakowania to Sliminator może być całkiem przydatny.",
        img: burn_rev1
    },
    rev2: {
        name: "Olek z Poznania",
        text: "Zdecydowałem się na Sliminator z nadzieją, że pomoże mi schudnąć chociaż połowę tego, co miałem do zrzucenia. Niestety nic takiego się nie zadziało. Owszem waga spadała, ale bardzo powoli. No i niestety jak chciałem uzupełnić zapasy, pojawił się problem. Cena się zmieniła i zdecydowałem, że nie warto. Lepiej poszukać czegoś, co naprawdę pomoże w krótkim czasie.",
        img: burn_rev2
    }
}

export default burnInfo