import moring from "../../images/moring30.png"
import moring_after from "../../images/moring_after.jpeg";
import moring_revEx from "../../images/moring_revEx.png"
import moring_rev1 from "../../images/moring_rev1.jpeg"
import moring_rev2 from "../../images/moring_rev2.jpeg"
import moring_rev3 from "../../images/moring_rev3.jpeg"

const moringInfo = {
    prodImg: moring,
    prodName: "#1 Moring Slim",
    revData: [97, 90, 100, 95, 92],
    rev: 9.7,
    readerText: "Spójrzcie na mnie po miesięcznej kuracji Moring Slim! 14 kilo mniej w 5 tygodni! Coś niesamowitego! Czuję się piękna i atrakcyjna :) Nie wstydzę się już chodzić na basen, bo cellulit znikł wraz z kilogramami tłuszczu.",
    changeImg: moring_after,
    readerName: "Monika z Jeleniej Góry, 29 lat",
    text: ["Szukasz naturalnego sposobu na utratę wagi? MORING SLIM FORMULA to produkt stworzony z myślą o tych, którzy chcą skutecznie pozbyć się zbędnych kilogramów bez efektu jo-jo i niepotrzebnych wyrzeczeń. Unikalna formuła pobudza metabolizm, przyspiesza proces spalania tłuszczu oraz pomaga osiągnąć wymarzoną sylwetkę.", "To naturalny produkt, który nie tylko pomaga w walce z nadwagą, ale także wpływa korzystnie na zdrowie organizmu.", "MORING SLIM FORMULA nie tylko stymuluje metabolizm, ale także przyspiesza proces spalania tłuszczu, umożliwiając osiągnięcie wymarzonej wagi bez konieczności podejmowania rygorystycznych diet.", "Suplement diety dostarcza organizmowi niezbędnych składników odżywczych, wspierając równowagę i witalność. To nie tylko suplement diety: to sposób na pełnię energii, dobre samopoczucie oraz skuteczny sojusznik w walce z nadwagą."],
    pros: ["innowacyjna formuła zamieniająca cukier w energię", "skuteczność i bezpieczeństwo stosowania", "pomoc w utrzymać prawidłowej masy ciała bez efektu jo-jo", "przyspieszenie spalania tłuszczu", "usprawnienie trawienia (bez wzdęć i odbijania po posiłku)", "z dobrze przebadanymi składnikami pochodzenia naturalnego"],
    cons: ["brak"],
    revEx: {
        name: "Jana Nowakowskiego",
        text: "Moring Slim zbiera wiele pozytywnych opinii od użytkowników, którzy dostrzegają wiele korzyści z jego stosowania. Wśród najczęstszych plusów wymienia się szybką i skuteczną utratę wagi, poprawę metabolizmu, zwiększenie energii oraz ogólne wsparcie dla zdrowego trybu życia. Dla wielu osób Moring Slim stał się skutecznym narzędziem w dążeniu do osiągnięcia wymarzonej sylwetki i poprawy samopoczucia, bez żadnych negatywnych skutków ubocznych.",
        img: moring_revEx,
    },
    rev1: {
        name: "Albert z Rzeszowa",
        text: "Od ponad 9 lat jestem, a właściwie byłem na różnych dietach. Z racji, że miałem stwierdzoną insulinooporność, to jak tylko straciłem kilka kilogramów, szybko powracały. Przez kilka ostatnich lat nie mogłem się nad tym zapanować. Dlatego wypróbowałem Moring Slim, chociaż przyznaję, nie wierzyłem jakoś szczególnie. Ale teraz cieszę się, że zdecydowałem się mu zaufać. Wreszcie wyglądam jak człowiek i czuję się po prostu szczęśliwy, że nie muszę się wstydzić swojego ciała. Mam zamiar zamówić jeszcze pakiet dla mojej mamy. Polecam!",
        img: moring_rev1
    },
    rev2 : {
        name: "Anna z Wrocławia",
        text: "Biorę Moring Slim zaledwie kilka tygodni. Spojrzałam dzisiaj w lustro i prawie się nie poznałam. Zamiast widzieć nieszczęśliwą kobietę z problemami z tarczycą... Zobaczyłam szczupłą kobietę, która wygląda na 10 lat młodszą! Od wielu lat mam niedoczynność tarczycy, dlatego, kiedy zobaczyłam pierwsze efekty, autentycznie się popłakałam. Wreszcie coś zadziałało! A do tej pory co schudłam, to waga wracała jeszcze większa. Miałam wrażenie, że czego nie zjem, to od razu tyję… Chcę, żeby wszyscy czuli się tak, jak ja, dlatego polecam!",
        img: moring_rev2
    },
    rev3: {
        name: "Grażyna z Warszawy",
        text: "O Moring Slim usłyszałam od znajomego, który przesłał mi ten artykuł. Po prostu przewróciłam oczami i pomyślałam, że to zbyt piękne, aby mogło być prawdziwe. Tym bardziej że mam niedoczynność tarczycy, która do tej pory przekreślała jakiekolwiek marzenia o szczupłej sylwetce. Na szczęście koleżanka zamówiła dwa opakowania i dała mi na spróbowanie.",
        img: moring_rev3
    }

}

export default  moringInfo