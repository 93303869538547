import {
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Heading,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const Form = ({ img, name, id, closeButton, time }) => {
  const min = Math.floor(time / 60);
  const sec = time % 60;
  const [data, setData] = useState({
    name: "",
    tel: "",
  });

  const addZero = (val) => {
    return val < 10 ? `0${val}` : val
  }

  useEffect(() => {
    function setUtm() {
      const queryParams = new URLSearchParams(window.location.search);
      const domain = window.location.hostname.replace("www.", "");

      if (queryParams.has("utm_source") || queryParams.has("utm_source_post")) {
        const content = queryParams.get("utm_content") || "-";
        const creation = queryParams.get("utm_creation") || "-";
        const campaign = queryParams.get("utm_campaign") || "-";
        const term = queryParams.get("utm_term") || "-";
        const medium = queryParams.get("utm_medium") || "-";
        const source = queryParams.get("utm_source") || "-";

        var url =
          "https://azurecrm20.pl/api/add_utm_stats/" +
          domain +
          "/" +
          encodeURIComponent(source.trim()) +
          "/" +
          encodeURIComponent(content.trim()) +
          "/" +
          encodeURIComponent(creation.trim()) +
          "/" +
          encodeURIComponent(campaign.trim()) +
          "/" +
          encodeURIComponent(term.trim()) +
          "/" +
          encodeURIComponent(medium.trim());

        fetch(url, {
          method: "GET",
        })
          .then((response) => {})
          .catch((error) => {});
      }
    }
    setUtm();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    //get of all utm for lead post
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = {};
    if (searchParams.has("utm_source")) {
      queryParams.utm_source = searchParams.get("utm_source");
      queryParams.utm_domain = window.location.hostname.replace("www.", "");
    }
    if (searchParams.has("utm_content")) {
      queryParams.utm_content = searchParams.get("utm_content");
    }
    if (searchParams.has("utm_creation")) {
      queryParams.utm_creation = searchParams.get("utm_creation");
    }
    if (searchParams.has("utm_campaign")) {
      queryParams.utm_campaign = searchParams.get("utm_campaign");
    }
    if (searchParams.has("utm_term")) {
      queryParams.utm_term = searchParams.get("utm_term");
    }
    if (searchParams.has("utm_medium")) {
      queryParams.utm_medium = searchParams.get("utm_medium");
    }
    // make name and surname
    const [first_name, last_name] = data.name.split(" ");
    //url for lead post
    var url =
      "https://azurecrm20.pl/api/add_lp_phone/" + data.tel + "/" + 4 + "/" + id;
    var body = {
      timestamp: CryptoJS.MD5(
        new Date().toISOString().split("T")[0]
      ).toString(),
      isfree: 3,
      first_name: first_name.trim(),
      ...(last_name && { last_name: last_name }),
      note: "147 PLN - LP.",
      ...(queryParams.utm_source && {
        utm_source: queryParams.utm_source,
        utm_domain: window.location.hostname.replace("www.", ""),
      }),
      ...(queryParams.utm_content && { utm_content: queryParams.utm_content }),
      ...(queryParams.utm_creation && {
        utm_creation: queryParams.utm_creation,
      }),
      ...(queryParams.utm_campaign && {
        utm_campaign: queryParams.utm_campaign,
      }),
      ...(queryParams.utm_term && { utm_term: queryParams.utm_term }),
      ...(queryParams.utm_medium && { utm_medium: queryParams.utm_medium }),
    };
    const formData = new FormData()
    for (const key in body) {
      if (Object.hasOwnProperty.call(body, key)) {
        formData.append(key, body[key])
      }
    }
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = "/thankyou";
        }
      })
      .catch();
    setData({ name: "", tel: "" });
  };

  return (
    <Flex
      width="100%"
      height="100%"
      position="fixed"
      left="0"
      top="0"
      zIndex="9999"
      background="rgba(0, 0, 0, 0.5)"
      flexDirection="column"
      justify="center"
      align="center"
    >
      <Flex
        background="white"
        padding={{ base: 2, md: 10 }}
        position="relative"
        borderRadius="25px"
        width={{ base: "100%", md: "auto" }}
        flexDirection={{ base: "column-reverse", md: "row" }}
        align={{ base: "center", md: "none" }}
      >
        <CloseButton
          position="absolute"
          top="10px"
          right="10px"
          color="red.400"
          size="lg"
          borderRadius="25px"
          onClick={closeButton}
        />
        <Flex
          flexDirection="column"
          align="center"
          width="65%"
          marginRight={{ base: 0, md: 10 }}
        >
          <Flex
            flexDirection="column"
            align="center"
            marginBottom={{ base: 2, md: 10 }}
          >
            <Heading color="blue.500">Poczekaj!</Heading>
            <Text textAlign="center" fontWeight="600">
              Schudnij nawet{" "}
              <Box as="span" color="blue.500">
                10kg w 32 dni!
              </Box>
            </Text>
            <Text textAlign="center" fontWeight="600">
              Już teraz odbierz{" "}
              <Box as="span" color="red.500">
                aż 50% rabatu{" "}
              </Box>
              na kuracje
            </Text>
            <Text
              textAlign="center"
              color="red.500"
              fontWeight="600"
              fontSize="24px"
            >
              {name}.
            </Text>
            <Text fontWeight="600" textAlign="center" fontSize={30} color="blue.500">147 PLN</Text>
            <Text fontWeight="600" textAlign="center">
              Uwaga ta oferta wygaśnie za: <Box as="span" fontSize="20" color="red.500" >{addZero(min)}:{addZero(sec)}</Box>
            </Text>
          </Flex>
          <form onSubmit={handleSubmit}>
            <FormControl
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2}
            >
              <FormLabel>Imię i nazwisko:</FormLabel>
              <Input
                type="text"
                id="name"
                name="name"
                value={data.name}
                onChange={handleChange}
                required
              />
              <FormHelperText>Tu wpisz swoje imię oraz nazwisko</FormHelperText>
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2}
            >
              <FormLabel>Numer telefonu:</FormLabel>
              <Input
                type="number"
                id="tel"
                name="tel"
                value={data.tel}
                onChange={handleChange}
                required
              />
              <FormHelperText>Tu wpisz swój numer telefonu</FormHelperText>
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Button
                type="submit"
                color="white"
                background="green.500"
                borderRadius="25px"
                width="100%"
              >
                Odbierz
              </Button>
            </FormControl>
          </form>
        </Flex>
        <Box width="35%" align="center">
          <Image src={img} maxWidth={{ base: 20, md: 200 }} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Form;
